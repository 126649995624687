import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "fill-current",
    width: $setup.size,
    height: $setup.size,
    viewBox: "0 0 24 24"
  }, [
    _createElementVNode("path", {
      class: "fill-current",
      d: $setup.iconPath
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}