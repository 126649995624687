import { defineStore } from 'pinia';
import { ProductModel } from '@/domains/models/product.model';

export const useProductStore = defineStore('productStore', {
  state: () => {
    return {
      product: {} as ProductModel
    };
  },
  getters: {
    getProduct: state => {
      return state.product;
    }
  },
  actions: {
    addProduct(productModel: ProductModel) {
      this.product = productModel;
    }
  }
});
