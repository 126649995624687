import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/apply',
    component: () => import(/* webpackChunkName: "process_base" */ '../views/process/base.vue'),
    meta: {
      hideFooter: true,
      hideNavMenu: true
    },
    children: [
      {
        path: '',
        name: 'processStep1',
        component: () => import(/* webpackChunkName: "process_base_step_1" */ '../views/process/step-1.vue')
      },
      {
        path: 'personal',
        name: 'processStep2',
        component: () => import(/* webpackChunkName: "process_base_step_2" */ '../views/process/step-2.vue')
      },
      {
        path: 'travel',
        name: 'processStep3',
        component: () => import(/* webpackChunkName: "process_base_step_3" */ '../views/process/step-3.vue')
      },
      {
        path: 'elegibility',
        name: 'processStep4',
        component: () => import(/* webpackChunkName: "process_base_step_4" */ '../views/process/step-4.vue')
      },
      {
        path: 'terms',
        name: 'processStep5',
        component: () => import(/* webpackChunkName: "process_base_step_5" */ '../views/process/step-5.vue')
      },
      {
        path: 'process',
        name: 'processStep6',
        component: () => import(/* webpackChunkName: "process_base_step_payment" */ '../views/process/payment.vue')
      }
    ]
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "component_terms" */ '../views/page.terms.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "component_privacy" */ '../views/page.privacy.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 150
      };
    } else {
      return {
        top: 0
      };
    }
  }
});

trackRouter(router);

export default router;
