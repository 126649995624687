import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ffcc1e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = ["placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AtomInput"], {
      label: $setup.label,
      "is-focus": $setup.isFocus,
      disabled: $setup.disabled,
      error: $setup.error
    }, {
      default: _withCtx(() => [
        _createElementVNode("input", {
          type: "text",
          onFocusin: _cache[0] || (_cache[0] = ($event: any) => ($setup.isFocus = true)),
          onFocusout: _cache[1] || (_cache[1] = ($event: any) => ($setup.isFocus = false)),
          placeholder: $setup.placeholder,
          value: $props.modelValue,
          onInput: $setup.updateModelValue,
          disabled: $setup.disabled
        }, null, 40, _hoisted_2)
      ]),
      _: 1
    }, 8, ["label", "is-focus", "disabled", "error"]),
    ($setup.error)
      ? (_openBlock(), _createBlock($setup["InputError"], { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}