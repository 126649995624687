import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e13fdfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "selected"
}
const _hoisted_3 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_4 = {
  key: 0,
  class: "card-options",
  ref: "targetOutside"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "country-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCustomIcon = _resolveComponent("BaseCustomIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AtomInput"], {
      label: $setup.label,
      "is-focus": $setup.showListOptions,
      error: $setup.error
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["input-select", {'active': $setup.showListOptions, 'error': $setup.error}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showListOptions = !$setup.showListOptions)),
          ref: "tarjetIgnore"
        }, [
          ($setup.selectedOption)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.selectedOption.value), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.placeholder), 1)),
          _createVNode(_component_BaseCustomIcon, {
            class: "icon",
            nameIcon: "mdiChevronDown"
          })
        ], 2),
        ($setup.showListOptions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listOptions, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "option",
                  onClick: ($event: any) => ($setup.updateModelValue(option.id))
                }, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(option.value), 1)
                ], 8, _hoisted_5))
              }), 256))
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label", "is-focus", "error"]),
    ($setup.error && $props.showErrorMessage)
      ? (_openBlock(), _createBlock($setup["InputError"], { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}