import { upperFirst, camelCase } from 'lodash';
import { App } from 'vue';

const requireComponent = require.context(
  // La ruta relativa de la carpeta de componentes
  '../components/base',
  // Si mirar o no en las subcarpetas
  true,
  // La expresión regular utilizada para hacer coincidir los nombres de los componentes básicos de los ficheros
  /[A-Za-z]\w+\.(vue)$/
);

export const registerComponents = (app: App<Element>) => {
  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(camelCase(`Base${upperFirst(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))}`));
    console.log(componentName);
    app.component(componentName, componentConfig.default || componentConfig);
  });
};
