import { ProductModel } from '@/domains/models/product.model';
import { ProductRepositoryInterface } from '@/domains/repositories/productRepository.interface';
import { AxiosFactory } from '../repository.config';

export class ProductRepository extends AxiosFactory implements ProductRepositoryInterface {
  constructor() {
    super('/products');
  }

  async getProduct() {
    try {
      const { data } = await this.axiosApi.get<ProductModel>('');
      return new ProductModel(data);
    } catch (error) {
      return new ProductModel({});
    }
  }
}
