import { AxiosFactory, CustomAxiosResponse } from '../repository.config';
import { CreateRequestDto, PaymentDataDto } from './request.dto';
import { IntentPaymentResponse } from './request.response';

export class RequestsRepository extends AxiosFactory {
  constructor() {
    super('/requests');
  }
  RESOURCE = '/requests';

  async createRequests(data: CreateRequestDto): Promise<CustomAxiosResponse<any>> {
    return await this.axiosApi.post('', {
      ...data
    });
  }

  async paymentRequest(request_id: string, paymentData: PaymentDataDto): Promise<CustomAxiosResponse<IntentPaymentResponse>> {
    return await this.axiosApi.post(`/${request_id}`, {
      is_express: paymentData.is_express,
      payment_token: paymentData.payment_token,
      product_id: paymentData.product_id
    });
  }
}
