import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02d8d236"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "selected"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "country-name" }
const _hoisted_5 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_6 = {
  key: 0,
  class: "card-options",
  ref: "targetOutside"
}
const _hoisted_7 = { class: "finder" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "country-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCustomIcon = _resolveComponent("BaseCustomIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AtomInput"], {
      label: $setup.label,
      "is-focus": $setup.showListOptions,
      error: $setup.error
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["input-select", {'active': $setup.showListOptions, 'error': $setup.error}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showListOptions = !$setup.showListOptions)),
          ref: "tarjetIgnore"
        }, [
          ($setup.selectedOption)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: "country-flag",
                  src: `/theme/flags/${$setup.selectedOption.flag}`
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, _toDisplayString($setup.selectedOption.name), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, "Seleccione una opción")),
          _createVNode(_component_BaseCustomIcon, {
            class: "icon",
            nameIcon: "mdiChevronDown"
          })
        ], 2),
        ($setup.showListOptions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BaseCustomIcon, {
                  class: "icon",
                  nameIcon: "mdiMagnify"
                }),
                _withDirectives(_createElementVNode("input", {
                  placeholder: "Buscar",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.keywordToFind) = $event))
                }, null, 512), [
                  [_vModelText, $setup.keywordToFind]
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listCountries, (country) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "option",
                  onClick: ($event: any) => ($setup.updateModelValue(country.country_code_3))
                }, [
                  _createElementVNode("img", {
                    class: "country-flag",
                    src: `/theme/flags/${country.flag}`
                  }, null, 8, _hoisted_9),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(country.name), 1)
                ], 8, _hoisted_8))
              }), 256))
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label", "is-focus", "error"]),
    ($setup.error)
      ? (_openBlock(), _createBlock($setup["InputError"], { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}