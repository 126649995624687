import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd9dad8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInputLabel = _resolveComponent("BaseInputLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group-input", {'error': $setup.error}])
  }, [
    ($setup.label != '')
      ? (_openBlock(), _createBlock(_component_BaseInputLabel, {
          key: 0,
          disabled: $props.disabled,
          error: $setup.error
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.label), 1)
          ]),
          _: 1
        }, 8, ["disabled", "error"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["box-input", {'active': $setup.isFocus}])
    }, [
      ($props.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}