import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group flex flex-col w-full" }
const _hoisted_2 = { class: "group flex w-full gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInputLabel = _resolveComponent("BaseInputLabel")!
  const _component_BaseInputSelect = _resolveComponent("BaseInputSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseInputLabel, { error: $setup.error }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.label), 1)
      ]),
      _: 1
    }, 8, ["error"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseInputSelect, {
        placeholder: "Dia",
        "list-options": $setup.daysOfMonth,
        modelValue: $setup.day,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.day) = $event)),
        showErrorMessage: false,
        error: $setup.day_error,
        onSetError: _cache[1] || (_cache[1] = ($event: any) => ($setup.day_error = false))
      }, null, 8, ["list-options", "modelValue", "error"]),
      _createVNode(_component_BaseInputSelect, {
        placeholder: "Mes",
        "list-options": $setup.nameOfMonth,
        modelValue: $setup.month,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.month) = $event)),
        showErrorMessage: false,
        error: $setup.month_error,
        onSetError: _cache[3] || (_cache[3] = ($event: any) => ($setup.month_error = false))
      }, null, 8, ["list-options", "modelValue", "error"]),
      _createVNode(_component_BaseInputSelect, {
        placeholder: "Año",
        "list-options": $setup.listYears,
        modelValue: $setup.year,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.year) = $event)),
        showErrorMessage: false,
        error: $setup.year_error,
        onSetError: _cache[5] || (_cache[5] = ($event: any) => ($setup.year_error = false))
      }, null, 8, ["list-options", "modelValue", "error"])
    ]),
    ($setup.error)
      ? (_openBlock(), _createBlock($setup["InputError"], {
          key: 0,
          customMessage: "La fecha esta incompleta"
        }))
      : _createCommentVNode("", true)
  ]))
}