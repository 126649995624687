export class ProductModel {
  _id: string;
  name: string;
  amount = 6999;
  apply_express = 1999;
  currency = 'USD';
  sign = '$';
  country: string;

  constructor(data: Partial<ProductModel>) {
    Object.assign(this, data);
  }

  getPriceBase = () => (this.amount / 100).toFixed(2);
  getPriceExpress = () => (this.apply_express / 100).toFixed(2);
  getPriceWithExpress = () => ((this.amount + this.apply_express) / 100).toFixed(2);
}
