import { App } from 'vue';
import { ProductRepository } from './products/product.repository';
import { RequestsRepository } from './requests/requests.repository';

export default {
  install: (app: App) => {
    app.provide('productRepository', new ProductRepository());
    app.provide('requestsRepository', new RequestsRepository());
  }
};
