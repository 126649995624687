import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1661cd00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCustomIcon = _resolveComponent("BaseCustomIcon")!
  const _component_BaseInputLabel = _resolveComponent("BaseInputLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "group-checkbox",
      onClick: $setup.updateValue
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["box-check", {'error': $setup.error}])
      }, [
        ($setup.modelValue)
          ? (_openBlock(), _createBlock(_component_BaseCustomIcon, {
              key: 0,
              class: "icon",
              nameIcon: "mdiCheck"
            }))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_BaseInputLabel, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ])
  ]))
}