import { createApp, inject } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

import './style.scss';

import { registerComponents } from './plugins/baseLoader';
//** analytics */
import VueGtag from 'vue-gtag-next';

import createContainerRepositories from './repositories/repository.container';
import { RequestsRepository } from './repositories/requests/requests.repository';

import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
  store.requestsRepository = new RequestsRepository();
});

registerComponents(app);

// const locale = localStorage.getItem('invoify_locale') || navigator.language.slice(0, 2).toLowerCase();

app
  .use(createContainerRepositories)
  .use(router)
  .use(pinia)
  .use(VueGtag, {
    property: { id: 'G-SVHHZJJQRT' },
    router
  })
  .use(VueAnimXyz)
  .mount('#app');
